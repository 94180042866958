import React from 'react'
import PropTypes from 'prop-types'

const SearchInput = ({
  inputClass,
  wrapperClass,
  handleKeypress,
  query,
  setQuery,
}) => {
  return (
    <div className={wrapperClass}>
      <div className={`${wrapperClass}--animation-left`}></div>
      <div className={`${wrapperClass}--animation-center`}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          stroke="#262626"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          className="feather feather-search"
        >
          <circle cx="11" cy="11" r="8"></circle>
          <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
        </svg>
        <input
          className={inputClass}
          onKeyPress={handleKeypress}
          placeholder="Search"
          type="text"
          name="query"
          value={query}
          onChange={(e) => setQuery(e.target.value)}
          id="searchBarMobileInput"
          title="search"
          aria-label="search"
        />
      </div>
      <div className={`${wrapperClass}--animation-right`}></div>
    </div>
  )
}

SearchInput.propTypes = {
  inputClass: PropTypes.string,
  wrapperClass: PropTypes.string,
  handleKeypress: PropTypes.func.isRequired,
  query: PropTypes.string,
  setQuery: PropTypes.func,
}

export default SearchInput
