export const getCurrentLevelFromMenuClick = (element) => {
  let currentElement = element
  let totalClassList = []
  while (currentElement) {
    totalClassList = totalClassList.concat([...currentElement.classList])
    currentElement = currentElement.nextElementSibling
  }
  const nextLevelClass = totalClassList.find((a) => a.includes('level--'))
  if (nextLevelClass) {
    const nextLevelNumber =
      nextLevelClass[nextLevelClass.indexOf('level--') + 'level--'.length]
    return nextLevelNumber - 1
  }
}

export const getCurrentLevelFromBackClick = (backContainer) => {
  const backText = backContainer.querySelector('.menu__back').innerHTML
  if (
    (backText.indexOf('Dia Box') > -1 &&
    backText.indexOf('Get a Dia Box') < 0) ||
    backText.indexOf('Brands') > -1) {
      return 2
  }
  return 1
}
