export const ENV = process.env.NODE_ENV || 'staging'

const ENV_VARS = {
  test: {
    baseUrlWWW: 'http://localhost:3000',
    baseUrlShop: 'https://shop-staging.dia.com',
    apiUrl: 'http://localhost:3000/external/navigations/navbar_content',
    storefrontAPIKey: 'c262cf1212702538604c13d82a11c8d7',
    storefrontAPIPath: 'https://diaandco.myshopify.com/api/2021-07/graphql',
    searchSpringAPIUrl: 'https://y9hlqv.a.searchspring.io/api/',
    searchSpringSiteId: 'y9hlqv',
    signInUrl: 'http://localhost:3000/users/sign_in',
  },
  development: {
    baseUrlWWW: 'http://localhost:3000',
    baseUrlShop: 'https://shop-staging.dia.com',
    apiUrl: 'http://localhost:3000/external/navigations/navbar_content',
    storefrontAPIKey: 'c262cf1212702538604c13d82a11c8d7',
    storefrontAPIPath: 'https://diaandco.myshopify.com/api/2021-07/graphql',
    searchSpringAPIUrl: 'https://y9hlqv.a.searchspring.io/api/',
    searchSpringSiteId: 'y9hlqv',
    signInUrl: 'http://localhost:3000/users/sign_in',
  },
  staging: {
    baseUrlWWW: 'https://staging.dia.com',
    baseUrlShop: 'https://shop-staging.dia.com',
    apiUrl: 'https://staging.dia.com/external/navigations/navbar_content',
    storefrontAPIKey: 'c262cf1212702538604c13d82a11c8d7',
    storefrontAPIPath: 'https://diaandco.myshopify.com/api/2021-07/graphql',
    searchSpringAPIUrl: 'https://y9hlqv.a.searchspring.io/api/',
    searchSpringSiteId: 'y9hlqv',
    signInUrl: 'https://staging.dia.com/users/sign_in',
  },
  production: {
    baseUrlWWW: 'https://www.dia.com',
    baseUrlShop: 'https://shop.dia.com',
    apiUrl: 'https://www.dia.com/external/navigations/navbar_content',
    storefrontAPIKey: '8901132654acec5aa8eaef817bcd971a',
    storefrontAPIPath: 'https://dia-com.myshopify.com/api/2021-07/graphql',
    searchSpringAPIUrl: 'https://ylqg8i.a.searchspring.io/api/',
    searchSpringSiteId: 'ylqg8i',
    signInUrl: 'https://www.dia.com/users/sign_in',
  },
}

export const getEnvVar = (key) => {
  let vars = ENV_VARS[ENV]
  if (ENV === 'staging' && window.location.host.match(/dia-qa/)) {
    vars = {
      ...vars,
      baseUrlWWW: '/',
      apiUrl: '/external/navigations/navbar_content',
      signInUrl: '/users/sign_in',
    }
  }
  return vars[key]
}
