import {
  getCurrentIsMouseInDropdown,
  getCurrentIsMouseInLink,
  getCurrentLink,
  getDropdownElements,
  getStaticLinkElements,
  hideNavDropdown,
  hideOldLinkContent,
  setCurrentIsMouseInDropdown,
  setCurrentIsMouseInLink,
  showNavDropdown,
} from '../desktopDropdown'
import {
  makeInvisible,
  makeVisible,
  setDisplayFlex,
  setDisplayNone,
} from '../utils'

const addDropdownMouseleaveListeners = () => {
  const { dropdown } = getDropdownElements()

  if (dropdown) {
    dropdown.addEventListener('mouseleave', hideNavDropdown)
    dropdown.addEventListener('mouseover', () =>
      setCurrentIsMouseInDropdown(true)
    )
    dropdown.addEventListener('mouseleave', () =>
      setCurrentIsMouseInDropdown(false)
    )
  }
}

const addStaticLinkMouseleaveListeners = () => {
  const staticLinks = getStaticLinkElements()
  Object.values(staticLinks).forEach((link) => {
    link &&
      link.addEventListener('mouseleave', () => {
        link.classList.remove('open')
      })
  })
}

const addDropdownMouseoverListeners = () => {
  const { bottomRowLinks } = getDropdownElements()
  const staticLinks = getStaticLinkElements()

  bottomRowLinks.forEach((link) => {
    if (!Object.values(staticLinks).includes(link)) {
      link.removeAttribute('href')
    }

    const linkContent = document.querySelector('#link-' + link.id)

    if (link) {
      link.addEventListener('mouseover', () => {
        setCurrentIsMouseInLink(true)
        if (getCurrentLink()) {
          hideOldLinkContent()
        }
        link.classList.add('open')

        if (linkContent) {
          showNavDropdown(link, linkContent)
        } else {
          hideNavDropdown()
        }
      })

      link.addEventListener('mouseleave', () => {
        setCurrentIsMouseInLink(false)
        setTimeout(() => {
          if (!getCurrentIsMouseInDropdown() && !getCurrentIsMouseInLink()) {
            hideNavDropdown()
          }
        }, 400)
      })
    }
  })
}

const addScrollListener = () => {
  const desktopNav = document.querySelector('.navigation__desktop')
  const sticky = desktopNav.offsetTop + 60
  const stickyLinksLeft = document.querySelector('.bottom-row__links-left')
  const stickyLinksRight = document.querySelector('.bottom-row__links-right')
  const stickyLogoSmallDesktop = document.querySelector('.bottom-row__logo')

  window.onscroll = () => {
    if (window.pageYOffset > sticky) {
      desktopNav.classList.add('sticky')
      stickyLogoSmallDesktop.classList.add('bottom-row__logo__visible')
      makeVisible(stickyLinksLeft)
      makeVisible(stickyLinksRight)
    } else {
      desktopNav.classList.remove('sticky')
      stickyLogoSmallDesktop.classList.remove('bottom-row__logo__visible')
      makeInvisible(stickyLinksLeft)
      makeInvisible(stickyLinksRight)
    }
  }

  const mobileNav = document.querySelector('.navigation__mobile')
  const mobileSticky = mobileNav.offsetTop

  window.addEventListener('scroll', () => {
    if (window.pageYOffset > mobileSticky) {
      mobileNav.classList.add('sticky')
    } else {
      mobileNav.classList.remove('sticky')
    }
  })
}

const addAccountListener = () => {
  let count = 0
  const updateCount = (number) => {
    count = number
    const myAccountMenu = document.querySelector(
      '.top-row__links--my-account__menu'
    )
    const myAccountMenuButton = document.getElementById(
      'nav-link__desktop--my-account'
    )

    //only want to hide menu if both are not hovered over
    if (count > 0) {
      const accountPos = myAccountMenuButton.getBoundingClientRect()
      const topOffset = -20
      myAccountMenu.style.top = accountPos.top + topOffset + 'px'
      setDisplayFlex(myAccountMenu)
    } else {
      setDisplayNone(myAccountMenu)
    }
  }

  const myAccountMenu = document.querySelector(
    '.top-row__links--my-account__menu'
  )
  const myAccountMenuButton = document.getElementById(
    'nav-link__desktop--my-account'
  )

  myAccountMenuButton &&
    myAccountMenuButton.addEventListener('mouseenter', () => {
      setTimeout(() => {
        updateCount(count + 1)
      }, 200)
    })
  myAccountMenuButton &&
    myAccountMenuButton.addEventListener('mouseleave', () => {
      setTimeout(() => {
        updateCount(count - 1)
      }, 400)
    })

  myAccountMenu &&
    myAccountMenu.addEventListener('mouseenter', () => {
      setTimeout(() => {
        updateCount(count + 1)
      }, 200)
    })
  myAccountMenu &&
    myAccountMenu.addEventListener('mouseleave', () => {
      setTimeout(() => {
        updateCount(count - 1)
      }, 400)
    })
}

export const addDesktopDropdownListeners = () => {
  addDropdownMouseoverListeners()
  addDropdownMouseleaveListeners()
  addStaticLinkMouseleaveListeners()
  addScrollListener()
  addAccountListener()
}
