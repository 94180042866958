import { setTransition } from './styles'

export const addPromoBannerEffects = (container, amountOfMessages) => {
  if (amountOfMessages > 1) {
    setTransition(container, 'transform 1s ease-out 0s')

    let index = 1
    window.setInterval(function () {
      let translate = `-${index * 100}vw`
      index += 1
      if (index > amountOfMessages) {
        translate = '0vw'
        index = 1
      }
      container.style.transform = `translate(${translate})`
    }, 5000)
  }
}
