import { getCartCountFromCookie } from '../session'
import { addPromoBannerEffects } from './promoBanner'
import {
  setDisplayFlex,
  setWidth,
  setDisplayInlineBlock,
  setWhiteSpace,
  setTextAlignLeft,
  setTextAlignCenter,
  setAlignItemsCenter,
  setPadding,
  removeLeftPadding,
  removeRightPadding,
} from './styles'
import {
  desktopSignInHTML,
  desktopMyAccountHTML,
  mobileSignInHTML,
  mobileMyAccountHTML,
} from './htmlSnippets'

const createMessageContainer = (width) => {
  const messageContainer = document.createElement('div')
  setWidth(messageContainer, width)
  setWhiteSpace(messageContainer, 'normal')
  setTextAlignLeft(messageContainer)
  setDisplayFlex(messageContainer)
  setAlignItemsCenter(messageContainer)
  return messageContainer
}

const addMessageToContainer = (message, container) => {
  const messageElement = document.createElement('span')
  setWidth(messageElement, '100vw')
  setPadding(messageElement, '0px 10px')
  setDisplayInlineBlock(messageElement)
  setTextAlignCenter(messageElement)
  messageElement.insertAdjacentHTML('afterbegin', message)
  container.appendChild(messageElement)
}

const stylePromoBanner = (container) => {
  setWidth(container, `100%`)
  removeLeftPadding(container)
  removeRightPadding(container)
  container.innerHTML = ''
}

export const insertBannerHTML = (messages = []) => {
  const bannerContainer = document.querySelector('.promo-banner')
  if (bannerContainer) {
    const messageContainer = createMessageContainer(
      `${messages.length * 100}vw`
    )
    stylePromoBanner(bannerContainer, messages.length)
    for (const msg of messages) {
      addMessageToContainer(msg.message, messageContainer)
    }
    bannerContainer.appendChild(messageContainer)
    addPromoBannerEffects(messageContainer, messages.length)
  }
}

export const insertTbybHTML = (data) => {
  const desktopNav = document.querySelector('.navigation__desktop')
  desktopNav && desktopNav.insertAdjacentHTML('beforeend', data)
}

const getAccountElements = () => ({
  menu: document.querySelector('#menu'),
  searchContainer: document.querySelector('#searchContainer'),
  bottomRowAccount: document.querySelector(
    '.bottom-row__links-right__my-account'
  ),
})

export const getInsertedAccountElements = () => ({
  desktopMyAccount: document.querySelector('#nav-link__desktop--my-account'),
  menuLevels: document.querySelectorAll('.menu__container__top'),
  mobileMyAccountIcon: document.querySelector(
    '.nav-link__mobile--account > svg'
  ),
  mobileMyAccount: document.querySelector('#nav-link__mobile--sign-in'),
})

export const insertMyAccountHTML = (mobileSessionData, desktopSessionData) => {
  const { menu, searchContainer, bottomRowAccount } = getAccountElements()

  bottomRowAccount.classList.add('my-account--logged-in')
  searchContainer &&
    searchContainer.insertAdjacentHTML('afterend', desktopMyAccountHTML)
  menu && menu.insertAdjacentHTML('afterbegin', mobileMyAccountHTML)

  const { desktopMyAccount, mobileMyAccount, mobileMyAccountIcon } =
    getInsertedAccountElements()

  mobileMyAccount &&
    mobileMyAccount.insertAdjacentHTML('afterend', mobileSessionData)

  mobileMyAccountIcon.classList.add('nav-link__mobile__icon--logged-in')

  const myAccountMenu = mobileMyAccount.nextElementSibling
  myAccountMenu.classList.add('my-account-menu')
  myAccountMenu.style.top = '100px'

  desktopMyAccount &&
    desktopMyAccount.insertAdjacentHTML('afterend', desktopSessionData)

  const mobileMyAccountMenu = mobileMyAccount.nextElementSibling
  mobileMyAccountMenu.classList.add('my-account-menu')
  mobileMyAccountMenu.style.top = '100px'
}

export const insertSignInHTML = () => {
  const { menu, searchContainer } = getAccountElements()

  searchContainer &&
    searchContainer.insertAdjacentHTML('afterend', desktopSignInHTML)
  menu && menu.insertAdjacentHTML('afterbegin', mobileSignInHTML)
}

export const insertNavLinkHTML = (data) => {
  for (const [id, html] of Object.entries(data)) {
    const dropdownDiv = document.querySelector(`#link-${id}`)
    const menuDiv = document.querySelector(`.nav-link__${id}`)

    if (dropdownDiv) {
      dropdownDiv.insertAdjacentHTML('beforeend', html)
    }

    if (menuDiv) {
      menuDiv.insertAdjacentHTML('afterend', html)
    }
  }
}

export const insertCartItemCount = () => {
  const desktopCartCountContainerEl = document.querySelector(
    '.nav-link__desktop__cart-count-container'
  )
  const mobileCartCountContainerEl = document.querySelector(
    '.nav-link__mobile__cart-count-container'
  )
  const desktopCartCountEl = document.querySelector(
    '.nav-link__desktop__cart-count'
  )
  const mobileCartCountEl = document.querySelector(
    '.nav-link__mobile__cart-count'
  )

  const cartCount = getCartCountFromCookie()

  if (cartCount && cartCount > 0) {
    desktopCartCountEl.innerHTML = cartCount
    mobileCartCountEl.innerHTML = cartCount
    setDisplayFlex(desktopCartCountContainerEl)
    setDisplayFlex(mobileCartCountContainerEl)
  }
}

export const insertFooterHTML = (data) => {
  for (const [id, html] of Object.entries(data)) {
    if (html) {
      document.querySelector(`#${id}`).innerHTML = html
    }
  }
}

export const checkIfFooterIsReady = (data) => {
  if (document.readyState === 'loading') {
    document.addEventListener(
      'DOMContentLoaded',
      function () {
        insertFooterHTML(data)
      },
      false
    )
  } else {
    insertFooterHTML(data)
  }
}
