import 'regenerator-runtime/runtime'
import { renderSearch } from './search'
import {
  insertBannerHTML,
  insertCartItemCount,
  insertMyAccountHTML,
  insertNavLinkHTML,
  insertSignInHTML,
  insertTbybHTML,
} from './utils'
import { updateCartCount } from './cart'
import {
  addDesktopDropdownListeners,
  addMobileMenuListeners,
  addTbybListeners,
} from './listeners'
import {
  EXPIRES_AT,
  LOGGED_IN_RESPONSE,
  LOGGED_OUT_RESPONSE,
} from './constants'
import { fetchData } from './data'
import { getLoggedInStatus } from './session'
import { checkCartChanged } from './utils/checkCartChanged'
import { checkIfFooterIsReady } from './utils/insertHTML'

const getItemFromLocalStorage = (key) => {
  return JSON.parse(localStorage.getItem(key))
}

const appendNavData = async () => {
  const isLoggedIn = getLoggedInStatus()
  const response = isLoggedIn ? LOGGED_IN_RESPONSE : LOGGED_OUT_RESPONSE
  const navData = getItemFromLocalStorage(response)
  const tbybData = navData['desktop-callout']
  const mobileSessionData = navData['mobile-session-status']
  const desktopSessionData = navData['desktop-session-status']

  const bannerData = getItemFromLocalStorage('banners')
  const bannerMessages = bannerData['messages']

  bannerMessages.length > 0 && insertBannerHTML(bannerMessages)

  const footerData = getItemFromLocalStorage('footer')
  checkIfFooterIsReady(footerData)

  insertTbybHTML(tbybData)
  insertNavLinkHTML(navData)
  insertCartItemCount()
  updateCartCount()
  window.setInterval(checkCartChanged, 250)

  if (isLoggedIn) {
    insertMyAccountHTML(mobileSessionData, desktopSessionData)
  } else {
    insertSignInHTML()
  }
}

export const initNav = () => {
  const prevExpTime = Date.parse(localStorage.getItem(EXPIRES_AT))
  const isNavExpired = prevExpTime < Date.now()
  const isLoggedIn = getLoggedInStatus()
  const response = isLoggedIn ? LOGGED_IN_RESPONSE : LOGGED_OUT_RESPONSE
  const isResponseStored = !!localStorage.getItem(response)
  const isFooterStored = !!localStorage.getItem('footer')
  const shouldFetchData =
    isNaN(prevExpTime) || isNavExpired || !isResponseStored || !isFooterStored

  return Promise.resolve()
    .then(shouldFetchData && fetchData)
    .then(appendNavData)
    .then(() => {
      addTbybListeners()
      addMobileMenuListeners()
      renderSearch()
      addDesktopDropdownListeners()
    })
}

initNav()
