import { closeOverlay, openOverlay, makeInvisible, makeVisible, setDisplayBlock, setDisplayNone } from './utils'
import { DROPDOWN_PADDING } from './constants'

export const getDropdownElements = () => ({
  bottomRowLinks: document.querySelectorAll('.bottom-row__link'),
  dropdown: document.querySelector('.dropdown'),
  dropdownContainer: document.querySelector('.dropdown__container'),
})

export const getStaticLinkElements = () => ({
  blogLink: document.querySelector('#desktop-blog'),
  saleLink: document.querySelector('#desktop-sale'),
})

// Hover listeners for revealing/hiding associated sub-menu
let currentLink = null
let isMouseInDropdown = false
let isMouseInLink = false;

export const getCurrentLink = () => currentLink
export const setCurrentLink = (link) => {
  currentLink = link
}

export const getCurrentIsMouseInDropdown = () => isMouseInDropdown
export const setCurrentIsMouseInDropdown = (result) => {
  isMouseInDropdown = result
}

export const getCurrentIsMouseInLink = () => isMouseInLink
export const setCurrentIsMouseInLink = (result) => {
  isMouseInLink = result
}

export const hideOldLinkContent = () => {
  const currentLink = getCurrentLink()
  currentLink.classList.remove('open')
  let oldLinkContent = document.querySelector('#link-' + currentLink.id)
  // Prevent overlapping when hovering over different links
  makeInvisible(oldLinkContent)
  oldLinkContent.style.top = '28px'
}

export const hideNavDropdown = () => {
  const { dropdown } = getDropdownElements()

  makeInvisible(dropdown)
  setDisplayNone(dropdown)
  closeOverlay('desktopMenuOverlay')
  if (currentLink) {
    hideOldLinkContent()
  }
}

export const showNavDropdown = (link, linkContent) => {
  const { dropdown, dropdownContainer } = getDropdownElements()

  openOverlay('desktopMenuOverlay')
  makeVisible(dropdown)
  setDisplayBlock(dropdown)
  dropdownContainer.style.height = `${
    linkContent.offsetHeight + DROPDOWN_PADDING * 2
  }px` // 102 = container top padding * 2

  setCurrentLink(link)
  makeVisible(linkContent)
  linkContent.style.top = `${DROPDOWN_PADDING}px`
}
