import {
  closeOverlay,
  makeVisible,
  mobileBackContainerHTML,
  mobileChevronHTML,
  openOverlay,
  setDisplayBlock,
  setDisplayFlex,
  setDisplayNone,
  transformTranslate,
} from './utils'
import getScreenWidth from './utils/getScreenWidth'

let menuOpen = false

export const getMenuOpen = () => menuOpen

export const setMenuOpen = (state) => {
  menuOpen = state
}

export const openMenu = () => {
  const {
    closeButton,
    hamburgerButtons,
    menu,
    closeButtonWrapper,
    menuContainer,
  } = getMenuElements()

  setMenuOpen(true)
  menu.classList.add('open')
  hamburgerButtons.forEach((button) => button.classList.add('open'))
  closeButton.classList.add('open')
  openOverlay('menu__overlay')
  setDisplayFlex(menuContainer)
  document.body.classList.add('menu-open')
  setDisplayBlock(closeButtonWrapper)
}

export const closeMenu = () => {
  const {
    closeButton,
    hamburgerButtons,
    menu,
    closeButtonWrapper,
    menuContainer,
  } = getMenuElements()

  setMenuOpen(false)
  menu.classList.remove('open')
  hamburgerButtons.forEach((button) => button.classList.remove('open'))
  closeButton.classList.remove('open')
  closeOverlay('menu__overlay')
  setDisplayNone(menuContainer)
  document.body.classList.remove('menu-open')
  setDisplayNone(closeButtonWrapper)
}

export const getMenuElements = () => ({
  accountNavButton: document.querySelector('.nav-link__mobile--account'),
  backContainer: document.querySelector('.menu__back__container'),
  closeButton: document.querySelector('.nav-link__mobile--close'),
  closeButtonWrapper: document.querySelector('.menu__close'),
  hamburgerButtons: document.querySelectorAll('.nav-link__mobile--hamburger'),
  menu: document.querySelector('#menu'),
  menuContainer: document.querySelector('.menu__container'),
  menuHeader: document.querySelector('.menu__header'),
  menuImage: document.querySelector('.menu__container__story--image'),
  navbar: document.querySelector('.navigation__mobile--navbar--right'),
  signIn: document.querySelector('.menu__sign-in'),
})

export const addMobileChevron = (level) => {
  if (
    !level.classList.contains('menu__container__story') &&
    !level.classList.contains('menu__sign-in')
  ) {
    level.insertAdjacentHTML(
      'beforeend',
      mobileChevronHTML('menu__container__story--arrow')
    )
  }
}

/*
 ** @param {string} direction - back | forward
 ** @param {string} parentTitle - title of the submenu
 ** Handles the menu stack back button text
 */
export const moveMobileMenu = (direction, parentTitle = '', level = 1) => {
  const { backContainer, menuHeader, menuImage, signIn } = getMenuElements()
  const previousTitle =
    parentTitle.indexOf('Brands') > -1 ? 'Shop By Brand' : 'My Account'
  let menuStack = []
  level > 1 && menuStack.push(previousTitle)
  level > 1 && direction == 'back' && menuStack.push(parentTitle)

  direction === 'back' && menuStack.pop()
  direction === 'forward' &&
    menuStack.push(parentTitle) &&
    setDisplayNone(signIn)

  if (menuStack.length === 0) {
    setDisplayNone(backContainer)
    setDisplayFlex(signIn)
    menuHeader.classList.remove('menu__header__submenu')
    makeVisible(menuImage)
  } else {
    setDisplayFlex(backContainer)
    menuHeader.classList.add('menu__header__submenu')
  }

  const backContainerTitle = menuStack.pop()
  backContainer.innerHTML = mobileBackContainerHTML(backContainerTitle)
}

const getTranslationNumber = (screenWidth, isTBYB) => {
  let translation
  if (isTBYB) {
    if (screenWidth < 330) {
      translation = -80
    } else if (screenWidth < 520) {
      translation = -80.5
    } else if (screenWidth < 769) {
      translation = -79.5
    } else {
      translation = -78.5
    }
  } else {
    if (screenWidth < 330) {
      translation = -85.2
    } else if (screenWidth < 520) {
      translation = -84
    } else if (screenWidth < 769) {
      translation = -82
    } else {
      translation = -81.3
    }
  }
  return translation
}

export const showMenu = (level, isTBYB = false) => {
  const vw = getScreenWidth()
  const { menuContainer } = getMenuElements()
  const translation = getTranslationNumber(vw, isTBYB)

  menu.scrollTop = 0

  const myAccountMenu = document.querySelector('.my-account-menu')

  if (level > 0) {
    myAccountMenu &&
      transformTranslate(myAccountMenu, translation * (level - 1))
    transformTranslate(menuContainer, translation * level)
  } else {
    setDisplayFlex(menuContainer)
    myAccountMenu && transformTranslate(myAccountMenu, 100)
    transformTranslate(menuContainer, 0)
  }
}
