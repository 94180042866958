import { hideNavDropdown } from '../desktopDropdown'
import { closeDesktopSearch, getSearchElements, renderSearch } from '../search'
import {
  closeOverlay,
  setDisplayBlock,
  setPosSticky,
  setPosUnset,
} from '../utils'

export const addSearchResizeListeners = () => {
  window.addEventListener('resize', renderSearch)
}

const mobileNavbar = document.querySelector('.navigation__mobile')

export const addMobileSearchOpenListeners = () => {
  const {
    searchBarContainerMobile,
    searchBarMobileInput,
    searchBarToggleMobile,
  } = getSearchElements()

  searchBarToggleMobile &&
    searchBarToggleMobile.addEventListener('click', () => {
      searchBarContainerMobile.classList.add(
        'navigation__mobile--search-screen--open'
      )
      searchBarMobileInput.focus()
      setPosUnset(mobileNavbar)
      window.scrollTo(0, 0)
    })
}

export const addMobileSearchCloseListeners = () => {
  const { searchBarCloseMobile, searchBarContainerMobile } = getSearchElements()

  searchBarCloseMobile &&
    searchBarCloseMobile.addEventListener('click', () => {
      searchBarContainerMobile.classList.remove(
        'navigation__mobile--search-screen--open'
      )
      closeOverlay('menu__overlay')
      setPosSticky(mobileNavbar)
    })
}

const openDesktopSearch = () => {
  const {
    fullSearchContainer,
    searchBarClose,
    searchBarContainerDesktop,
    searchBarInput,
    searchBarOverlay,
    searchBarWrapper,
  } = getSearchElements()

  hideNavDropdown()
  searchBarContainerDesktop.classList.add('search--focused')
  searchBarWrapper.classList.add('search-bar-wrapper--focused')
  searchBarInput.classList.add('search-bar-wrapper--focused__input')
  searchBarClose.classList.add('search__closeButton--show')
  searchBarOverlay.classList.add('search-bar-overlay--open')
  fullSearchContainer.classList.add(
    'search__focusContainer__popular-searches--open'
  )
  setDisplayBlock(fullSearchContainer)
}

export const addDesktopOpenListeners = () => {
  const { searchBarInput, searchIconDesktop } = getSearchElements()

  searchBarInput && searchBarInput.addEventListener('focus', openDesktopSearch)

  searchIconDesktop &&
    searchIconDesktop.addEventListener('click', openDesktopSearch)
}

export const addDesktopInputListeners = () => {
  const { fullSearchContainer, searchBarInput } = getSearchElements()

  searchBarInput &&
    searchBarInput.addEventListener('input', (e) => {
      if (e.target.value.length <= 2) {
        setDisplayBlock(fullSearchContainer)
      }
    })
}

export const addDesktopCloseListeners = () => {
  const { searchBarClose, searchBarOverlay } = getSearchElements()

  searchBarClose &&
    [searchBarClose, searchBarOverlay].forEach((element) => {
      element.addEventListener('click', closeDesktopSearch)
    })
}
