import {
  addMobileChevron,
  closeMenu,
  getMenuElements,
  getMenuOpen,
  moveMobileMenu,
  openMenu,
  showMenu,
} from '../mobileMenu'
import {
  makeInvisible,
  setDisplayBlock,
  getInsertedAccountElements,
  getCurrentLevelFromBackClick,
  getCurrentLevelFromMenuClick,
  setDisplayNone,
} from '../utils'
import { getEnvVar } from '../env'

import { getLoggedInStatus } from '../session'

export const addMobileMenuListeners = () => {
  addClickOpenListeners()
  addClickCloseListeners()
  addMobileSubMenuListeners()
  addMobileBackListener()
  addMobileAccountNavListener()
}

const addClickOpenListeners = () => {
  const { hamburgerButtons } = getMenuElements()

  hamburgerButtons.forEach((button) =>
    button.addEventListener('click', openMenu)
  )
}

const addClickCloseListeners = () => {
  const { closeButton, hamburgerButtons, menu, navbar } = getMenuElements()

  closeButton && closeButton.addEventListener('click', closeMenu)
  document.addEventListener('click', (event) => {
    const withinBoundaries = event.composedPath().includes(menu)
    const withinButton = Array.from(hamburgerButtons).some((button) =>
      event.composedPath().includes(button)
    )
    const withinNavbar = event.composedPath().includes(navbar)

    if (!withinButton && !withinNavbar) {
      if (getMenuOpen() && !withinBoundaries) {
        closeMenu()
      }
    }
  })
}

const getMobileMenuLevelType = (level) => {
  if (level.classList.contains('menu__container__story')) {
    return 'TBYB'
  }
  if (level.classList.contains('menu__sign-in')) {
    return 'MyAccount'
  }
  return 'submenu'
}

// Click listener on each link that goes to a sub-menu. Listener handles showing its menu
export const addMobileSubMenuListeners = () => {
  const { menuLevels } = getInsertedAccountElements()
  const { menuImage } = getMenuElements()

  menuLevels.forEach((level) => {
    if (level.nextElementSibling) {
      addMobileChevron(level)
      level.addEventListener('click', () => {
        const currentLevel = getCurrentLevelFromMenuClick(level)
        const newLevel = currentLevel + 1
        setDisplayBlock(level.nextElementSibling)
        makeInvisible(menuImage)
        const menuLevelType = getMobileMenuLevelType(level)

        if (menuLevelType === 'TBYB') {
          //tbyb uses image as level, so we can't use level.textContent
          moveMobileMenu('forward', 'Try Before You Buy')
          showMenu(newLevel, true)
          return
        }

        if (menuLevelType === 'MyAccount') {
          //my account level has add'l text, so we can't use level.textContent
          moveMobileMenu('forward', 'My Account')
        } else {
          moveMobileMenu('forward', level.textContent, newLevel)
        }
        showMenu(newLevel)
      })
    }
  })
}

// Click listener on back button for hiding sub-menus when pressed and showing the parent menu
export const addMobileBackListener = () => {
  const { backContainer } = getMenuElements()

  backContainer &&
    backContainer.addEventListener('click', () => {
      const actualLevel = getCurrentLevelFromBackClick(backContainer)
      const backText = backContainer.querySelector('.menu__back').innerHTML

      const visibleLevels = document.querySelectorAll(
        `.menu__container__level--${actualLevel}`
      )
      setTimeout(() => {
        visibleLevels.forEach((level) => setDisplayNone(level))
      }, 400)
      const newLevel = actualLevel - 1
      if (newLevel > 0) {
        moveMobileMenu('back', backText, actualLevel)
      } else {
        moveMobileMenu('back')
      }
      showMenu(newLevel)
    })
}

export const addMobileAccountNavListener = () => {
  const { accountNavButton } = getMenuElements()
  const { mobileMyAccount } = getInsertedAccountElements()

  accountNavButton &&
    accountNavButton.addEventListener('click', () => {
      const isLoggedIn = getLoggedInStatus()

      if (isLoggedIn) {
        const { menuContainer } = getMenuElements()
        let currentLevel = 1
        setDisplayBlock(mobileMyAccount.nextSibling)
        moveMobileMenu('forward', 'My Account')
        showMenu(currentLevel)
        openMenu()
        setDisplayNone(menuContainer)
        // TODO: Fix animation when going back from account menu opened thru nav button
      } else {
        window.location = getEnvVar('signInUrl')
      }
    })
}
