export const setDisplayNone = (element) => {
  if (element) {
    element.style.display = 'none'
  }
}

export const setDisplayFlex = (element) => {
  if (element) {
    element.style.display = 'flex'
  }
}

export const setDisplayBlock = (element) => {
  if (element) {
    element.style.display = 'block'
  }
}

export const setDisplayInlineBlock = (element) => {
  element.style.display = 'inline-block'
}

export const makeVisible = (element) => {
  if (element) {
    element.style.opacity = 100
    element.style.visibility = 'visible'
  }
}

export const makeInvisible = (element) => {
  if (element) {
    element.style.visibility = 'hidden'
    element.style.opacity = 0
  }
}

export const transformTranslate = (element, translationAmount) => {
  element.style.transform = `translate(${translationAmount}vw)`
}

export const setPosSticky = (element) => {
  element.style.position = 'sticky'
}

export const setPosRelative = (element) => {
  element.style.position = 'relative'
}

export const setPosUnset = (element) => {
  element.style.position = 'unset'
}

export const setTextAlignLeft = (element) => {
  element.style.textAlign = 'left'
}

export const setTextAlignCenter = (element) => {
  element.style.textAlign = 'center'
}

export const setAlignItemsCenter = (element) => {
  element.style.alignItems = 'center'
}

export const setTransition = (element, value) => {
  element.style.transition = value
}

export const setWidth = (element, value) => {
  element.style.width = value
}

export const setWhiteSpace = (element, value) => {
  element.style.whiteSpace = value
}

export const setPadding = (element, value) => {
  element.style.padding = value
}

export const removeLeftPadding = (element) => {
  element.style.paddingLeft = 0
}

export const removeRightPadding = (element) => {
  element.style.paddingRight = 0
}
